<template>
  <div class="d-flex py-6 pl-9 pr-7 align-top elevation-2 rounded">
    <div style="width: 90%;">
      {{ messageRecord.content }}
    </div>
    <v-spacer></v-spacer>
    <div class="sysdate">{{ sysNewDate }}</div>
  </div>
</template>

<script lang='js'>

export default {
  name: 'NotificationItem',
   props: {
      messageRecord: Object,
  },
  data: function() {
    return {
      sysNewDate:''
    };
  },
  created(){
    let that = this
    let time = this.messageRecord.createTime;//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    function timestampToTime() {
      let date = new Date(time)
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      let D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
      let h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
      let s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
      let strDate = Y+M+D+h+m+s;
      return that.sysNewDate = strDate;
    }
    timestampToTime()
  }
}

</script>

<style lang='scss' scoped>
    .sysdate {
        width: 200px !important;
    }
    .elevation-2 {
        box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 9%) !important;
        margin-top: 9px;
    }
</style>
