<template>
  <div class="overflow-y-auto">
    <NotificationItem
      class="mb-3"
      v-for="(item, i) in list"
      :key="i"
      :messageRecord="item"
      v-scroll="onScroll"
    />
     <div v-if="list.length == 0 && !isShowLoading" class="empty">{{ $t("EmptyHere") }}</div>
    <loading :isShowLoading="isShowLoading"></loading>
  </div>
  
</template>

<script lang='js'>
import NotificationItem from './components/NotificationItem.vue';
import Loading from "@/components/loading.vue";
import  api  from "@/api"
export default {
  name: 'Notifications',
  components: { NotificationItem,Loading },
  props: {},
  data: function() {
    return {
      isShowLoading: false,
        list: [],
      pageNumber: 0,
      pageSize: 20,
      totalPage: false,
    };
  },
  methods:{
    async getSysNews(){
      this.isShowLoading = true
      this.pageNumber++;
      let params={
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      let res = await api.home.sysNews(params)
      this.list = res.data.list
       this.isShowLoading = false
    },
    onScroll(e) {
      const {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = e.target.scrollingElement;
      if (
          scrollTop + clientHeight === scrollHeight &&
          this.totalPage > this.pageNumber
      ) {
        this.getSysNews();
      }
    },
  },
  mounted(){
    this.getSysNews()
  }
};

</script>

<style lang='scss' scoped>
.empty {
					width: 100%;
					height: 500px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
</style>
